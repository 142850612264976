<template>
  <div>
    <div>
      <div class="d-lg-flex d-block justify-content-between mt-65 mb-45">
        <div>
          <h2 class="text-primary">
            Farmer region
          </h2>
        </div>
        <div>
        </div>
      </div>
      <div class="bg-white py-40">
        <div class="row no-gutters p-20 p-lg-0">
          <div class="col-lg-7 col-md-12 m-auto">
            <div class="form-group">
              <div class="form-row">
                <div class="col-lg-3 col-md-12">
                  <label class="col-form-label">Region</label>
                </div>
                <div class="col-lg-7 col-md-12">
                  <div class="form-group">
                    <div class="drop drop-inline drop-sm">
                      <div class="cont">
                        <img alt=""
                             src="@/assets/img/icons/upload1.svg">
                        <div v-if="!regionForm.file.tsync_id" class="desc"> Upload Excel File</div>
                        <div v-if="regionForm.file.tsync_id" class="desc">
                          {{ regionForm.file.name | textTruncate(15) }}
                        </div>
                      </div>
                      <input
                        ref="fileUploader"
                        accept=".xls, .xlsx"
                        type="file"
                        @change="onExcelChange($event)"
                        @click="resetFileUploader"
                      >
                    </div>
                    <div class="d-inline-flex">
                      <i v-show="regionForm.file.tsync_id" aria-hidden="true"
                         class="fa fa-times ml-2 clear-file"
                         @click="clearFile(regionForm)"> Clear</i>
                    </div>
                  </div>
                  <h4 v-show="fileLoading" class="text-warning">
                    <span v-show="!Object.keys(regionContents).length">
                      {{ loadingText }}
                    </span>
                  </h4>
                  <h6 v-show="uploadPercentage"
                      :class="uploadPercentage === 100 ? 'text-success':'text-warning'">
                    <span v-show="uploadPercentage!==100">
                     Please don't close this TAB before the upload is 100% completed.
                    </span>
                  </h6>
                  <div v-show="uploadPercentage && uploadPercentage!==100" class="progress">
                    <div :style="'width: '+uploadPercentage+'%'" aria-valuemax="100"
                         aria-valuemin="0" aria-valuenow="1" class="progress-bar progress-bar-striped bg-success"
                         role="progressbar">{{ uploadPercentage }}%
                    </div>
                  </div>
                  <div
                    v-show="$v.$error & !$v.regionForm.file.tsync_id.required"
                    class="error">
                    An excel file is required.
                  </div>
                </div>
                <div class="col-lg-2 col-md-12">
                  <div class="text-right">
                    <div class="d-inline-flex">
                      <button v-if="!isObserverUser"
                              :disabled="!Object.keys(regionContents).length || !isSaveButtonActive"
                              class="btn btn-submit"
                              type="button"
                              @click="saveRegion">
                      <span v-show="!isSaveButtonActive" aria-hidden="true" class="spinner-border spinner-border-sm"
                            role="status"></span>
                        {{ saveButtonTxt }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="row">
                <div class="col-12 col-md-12 col-lg-12 col-sm-12">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white py-40 mt-5">
        <div class="row no-gutters p-20 p-lg-0">
          <div class="col-md-12 col-lg-12">
            <div class="content-heading d-flex justify-content-between align-items-center p-10">
              <h3 class="text-primary font-22"></h3>
              <div class="d-lg-flex d-md-block d-block mt-md-10">
                <div class="dropdown custom-dropdown mr-50 mb-md-10 mb-20 mb-lg-0">
                  <button id="dropdownMenuButton" aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle"
                          data-toggle="dropdown" type="button">
                    {{ searchDict.searchField.header || 'Select column' }}
                  </button>
                  <div aria-labelledby="dropdownMenuButton" class="dropdown-menu"
                       style="position: absolute; will-change: transform; top: 0; left: 0; transform: translate3d(0px, 40px, 0px);"
                       x-placement="bottom-start">
                    <a v-show="searchDict.searchField" class="dropdown-item" @click="selectColumn('')">Select column</a>
                    <a v-for="(searchConfig,_index) in tableSearchConfig" :key="_index"
                       class="dropdown-item"
                       @click="selectColumn(searchConfig)">{{ searchConfig.header }}</a>
                  </div>
                </div>
                <div class="search-form">
                  <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'text'"
                         v-model.trim="searchDict.searchText"
                         class="form-control"
                         placeholder="Search" type="text" @keyup.enter="applySearch()">
                  <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                         v-model.trim="searchDict.fromDate"
                         class="form-control mr-2" type="date" @keyup.enter="applySearch()">
                  <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date'"
                         v-model.trim="searchDict.date"
                         class="form-control"
                         type="date" @keyup.enter="applySearch()">
                  <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'date_range'"
                         v-model.trim="searchDict.toDate"
                         class="form-control"
                         type="date" @keyup.enter="applySearch()">
                  <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number'"
                         v-model.trim="searchDict.searchText"
                         class="form-control"
                         placeholder="Search" type="number" @keyup.enter="applySearch()">
                  <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                         v-model.trim="searchDict.fromNumber"
                         class="form-control mr-2" placeholder="From" type="number" @keyup.enter="applySearch()">
                  <input v-show="searchDict.searchField && searchDict.searchField.widget_type === 'number_range'"
                         v-model.trim="searchDict.toNumber"
                         class="form-control" placeholder="To" type="number" @keyup.enter="applySearch()">
                  <input v-show="!searchDict.searchField" v-model.trim="searchDict.searchText"
                         class="form-control" placeholder="Search" type="text" @keyup.enter="applySearch()">
                  <div>
                    <button class="btn btn-primary" @click="applySearch()">
                      <img alt="Search" src="@/assets/img/icons/search-glass.svg">
                    </button>
                    <button class="btn btn-outline-primary ml-2" @click="clearSearch()">
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-10">
              <table class="table table-striped mt-5">
                <thead>
                <tr>
                  <th v-for="(item, _i) in tableHeaders" :key="_i">{{ item.title }} <br> {{ item.subtitle }}</th>
                </tr>
                </thead>
                <tbody v-show="loading">
                <tr>
                  <td :colspan="tableHeaders.length">
                    <FBTableLoader :columns="tableHeaders.length" :height="300" :primaryColor="'#d3d3d3'" :rows="5"
                                   class="col-12"></FBTableLoader>
                  </td>
                </tr>
                </tbody>
                <tbody v-show="!loading">
                <tr v-for="(rowData, _rowIndex) in getTableData()" :key="_rowIndex">
                  <td v-for="(item, _i) in rowData" :key="_i">
                    <span v-html="item"></span>
                  </td>
                </tr>
                <tr v-show="getTableData().length === 0">
                  <td :colspan="tableHeaders.length">No data available.</td>
                </tr>
                </tbody>
              </table>
              <div class="container pl-0 pr-0">
                <div class="">
                  <div class="d-lg-flex d-block justify-content-between mt-15 mb-45">
                    <div>
                      <select v-model="paginateDict.dataPerPage" class="form-control admin-input justify-content-start"
                              @change="getContentsFromServer()">
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                    <div>
                      <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-lg justify-content-end">
                          <li :class="{disabled: !paginateDict.firstPage, 'page-item':true}">
                            <a class="page-link" @click="getPaginatedData(paginateDict.firstPage)">First</a>
                          </li>
                          <li v-show="paginateDict.previousPageNumber > 0"
                              :class="{disabled: !paginateDict.previousUrl, 'page-item':true}">
                            <a class="page-link"
                               @click="getPaginatedData(paginateDict.previousUrl)">{{
                                paginateDict.previousPageNumber
                              }}</a>
                          </li>
                          <li class="page-item disabled"><a class="page-link" href="#">{{
                              paginateDict.currentPageNumber
                            }}</a>
                          </li>
                          <li v-show="paginateDict.nextPageNumber > 0"
                              :class="{disabled: !paginateDict.nextUrl, 'page-item':true}">
                            <a class="page-link" @click="getPaginatedData(paginateDict.nextUrl)">{{
                                paginateDict.nextPageNumber
                              }}</a>
                          </li>
                          <li :class="{disabled: !paginateDict.lastPage, 'page-item':true}">
                            <a class="page-link" @click="getPaginatedData(paginateDict.lastPage)">Last</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import xlsx from 'xlsx'
import { required } from 'vuelidate/lib/validators'
import UtilityMixin from '@/mixins/utility-mixin'
import moment from 'moment'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'
import _ from 'lodash'
import { FBTableLoader } from '@/plugins/content-loader/core/components'

export default {
  name: 'AllCountryFarmerRegion',
  components: { FBTableLoader },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      tableHeaders: [
        {
          title: 'Country',
          subtitle: ''
        },
        {
          title: 'Region',
          subtitle: 'Name'
        },
        {
          title: 'Number',
          subtitle: 'of farmers'
        },
        {
          title: 'Altitude',
          subtitle: 'masl'
        },
        {
          title: 'Coffee',
          subtitle: 'Species'
        },
        {
          title: '',
          subtitle: 'Harvest Period (main)'
        },
        {
          title: 'Harvest',
          subtitle: 'Period (fly)'
        },
        {
          title: '',
          subtitle: 'Qualities'
        },
        {
          title: '',
          subtitle: ''
        }
      ],
      tableSearchConfig: [
        {
          header: 'Country',
          db_representation: 'country__name',
          widget_type: 'text'
        },
        {
          header: 'Region Name',
          db_representation: 'region__name',
          widget_type: 'text'
        },
        {
          header: 'Number of farmers',
          db_representation: 'farmers_total',
          widget_type: 'number_range'
        },
        {
          header: 'Altitude masl',
          db_representation: 'altitude_min,altitude_max',
          widget_type: 'number_range'
        },
        {
          header: 'Coffee Species',
          db_representation: 'coffee_species__name',
          widget_type: 'text'
        },
        {
          header: 'Harvest Period (main)',
          db_representation: 'main_start_date,main_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'Harvest Period (fly)',
          db_representation: 'fly_start_date,fly_end_date',
          widget_type: 'date_range'
        },
        {
          header: 'Qualities',
          db_representation: 'qualities__name',
          widget_type: 'text'
        }
      ],
      searchDict: {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      },
      paginateDict: {
        nextUrl: null,
        previousUrl: null,
        currentPage: 1,
        totalPage: 1,
        firstPage: null,
        lastPage: null,
        remainingCount: 0,
        nextOffset: 0,
        totalCount: 0,
        dataPerPage: 5,
        previousPageNumber: 0,
        currentPageNumber: 0,
        nextPageNumber: 0
      },
      apiPrefix: 'v1/region-meta',
      region: {},
      regionForm: {
        file: {
          file: null,
          name: null,
          tsync_id: null
        }
      },
      regionContents: {},
      tableData: [],
      loading: false,
      fileLoading: false,
      fileTsyncIdMapping: {},
      loadingText: 'Excel is loading. Please wait a moment',
      isSaveButtonActive: true,
      saveButtonTxt: 'Submit',
      uploadPercentage: 0
    }
  },
  computed: {
    regionContent () {
      return this.$store.getters.getRegionFarmerGroupAllCountryRegionContents
    }
  },
  validations: {
    regionForm: {
      file: {
        tsync_id: { required }
      }
    },
    searchDict: {
      searchField: {
        required
      }
    }
  },
  methods: {
    selectColumn (searchConfig) {
      this.searchDict = {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }
      this.searchDict.searchField = searchConfig
    },
    clearSearch () {
      this.searchDict = {
        searchField: '',
        searchText: '',
        date: '',
        fromDate: '',
        toDate: '',
        fromNumber: '',
        toNumber: ''
      }
      this.getContentsFromServer()
    },
    resetFileUploader () {
      // It reset image or file uploader if same file selected again
      this.$refs.fileUploader.value = ''
    },
    onExcelChange (e) {
      this.regionContents = {}
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.regionForm.file.file = file
        this.regionForm.file.name = file.name
        this.regionForm.file.tsync_id = this.uuidV4()
        this.fileLoading = false
        this.prepareContractsData()
        this.fileLoading = true
      } else {
        // if excel upload widget clicked and no file selected
        this.regionForm.file.file = null
        this.regionForm.file.name = null
        this.isChartPreviewed = false
        this.regionForm.file.tsync_id = null
      }
    },
    prepareContractsData () {
      if (this.regionForm.file.file) {
        const file = this.regionForm.file.file
        const fileReader = new FileReader()
        fileReader.onload = ev => {
          try {
            const data = ev.target.result
            const XLSX = xlsx
            const workbook = XLSX.read(data, {
              type: 'binary'
            })
            const wsname = workbook.SheetNames[0] // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])
            const excelData = [] // Clear received data
            //
            const wsLength = ws.length
            for (var i = 0; i < wsLength; i++) {
              excelData.push(ws[i])
            }
            this.regionContents = excelData
          } catch (e) {
            this.loadingText = 'File can\'t read. Please try again'
            return e
          }
        }
        fileReader.readAsBinaryString(file)
      }
    },
    async saveRegion () {
      this.$v.regionForm.$touch()
      if (this.$v.regionForm.$invalid) {
        this.$notify({
          title: '',
          text: 'Please fix error(s) in form.',
          type: 'error',
          duration: 5000
        })
      } else {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'
        const vm = this

        // start file upload log
        const fileUploadLogPromises = []
        const response = this.createFileUploadLog(this.regionForm)
        fileUploadLogPromises.push(response)

        Promise.all(fileUploadLogPromises).then((values) => {
          const filePromises = []
          values.map((item) => {
            const fileId = item.data.file ? item.data.file.id : 0
            const fileTsyncId = item.data.file ? item.data.file.tsync_id : ''
            if (fileId === 0 || fileTsyncId === '') return
            const file = this.fileTsyncIdMapping[fileTsyncId]
            if (!file) return
            const response = this.uploadFile(fileId, file)
            filePromises.push(response)
          })
          Promise.all(filePromises).then((fileResponses) => {
            // this.$notify({
            //   title: '',
            //   text: 'File upload log created successfully.',
            //   type: 'success', // 'warn', 'error', 'success'
            //   duration: 5000
            // })
            this.$forceUpdate()
          })
        })
        // end file upload log
        const regionPromises = []
        let regionUploadCount = 0
        const totalRegions = _.size(vm.regionContents)
        vm.regionContents.map((item) => {
          const response = vm.regionEachGroup(item)
          var myPromise = this.MakeQuerablePromise(response)
          response.then(function (data) {
            if (myPromise.isFulfilled()) {
              regionUploadCount += 1
              vm.uploadPercentage = Math.ceil(regionUploadCount / totalRegions * 100)
            }
          })
          regionPromises.push(response)
        })
        let dataPosted = 0
        let dataNotPosted = 0
        const tempData = []
        Promise.all(regionPromises).then((values) => {
          values.map((item) => {
            const data = item.data
            if (data.success) {
              if (!_.get(data, 'results')) {
                tempData.push(data)
              } else {
                this.$store.commit('setRegionFarmerRegionContent', data.results)
              }
              dataPosted += 1
            } else {
              this.$notify({
                title: 'Region',
                text: data.message,
                type: 'error', // 'warn', 'error', 'success'
                duration: 10000
              })
              dataNotPosted += 1
              vm.errorLog(item)
            }
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'

          if (tempData.length > 0) {
            vm.$store.commit('setRegionFarmerRegionContent', tempData)
          }
          if (dataNotPosted > 0) {
            this.$notify({
              title: 'Region',
              text: `${dataNotPosted} region not created/ updated.`,
              type: 'warn', // 'warn', 'error', 'success'
              duration: 5000
            })
          }
          if (dataPosted > 0) {
            this.$notify({
              title: 'Region',
              text: `${dataPosted} region created/ updated successfully.`,
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
          }
        })
        // this.$forceUpdate()
      }
    },
    async regionEachGroup (data) {
      try {
        const formData = this.$_.cloneDeep(data)
        // coffee specs
        if (_.get(formData, 'coffee_species')) {
          const coffeeSpeciesList = []
          let coffeeSpecies = formData.coffee_species.toString()
          coffeeSpecies = coffeeSpecies.split(',')
          coffeeSpecies.map((i) => coffeeSpeciesList.push({ name: i }))
          formData.coffee_species = coffeeSpeciesList
        }
        // coffee specs
        if (_.get(formData, 'harvest_method')) {
          const harvestMethodsList = []
          let harvestMethods = formData.harvest_method.toString()
          harvestMethods = harvestMethods.split(',')
          harvestMethods.map((i) => harvestMethodsList.push({ name: i }))
          formData.harvest_methods = harvestMethodsList
        }
        // processing methods
        if (_.get(formData, 'processing_method')) {
          const processingMethodsList = []
          let processingMethods = formData.processing_method.toString()
          processingMethods = processingMethods.split(',')
          processingMethods.map((i) => processingMethodsList.push({ name: i }))
          formData.processing_methods = processingMethodsList
        }
        // drying methods
        if (_.get(formData, 'drying_method')) {
          const dryingMethodsList = []
          let dryingMethods = formData.drying_method.toString()
          dryingMethods = dryingMethods.split(',')
          dryingMethods.map((i) => dryingMethodsList.push({ name: i }))
          formData.drying_methods = dryingMethodsList
        }
        // qualities
        if (_.get(formData, 'qualities')) {
          const qualitiesList = []
          let qualities = formData.qualities.toString()
          qualities = qualities.split(',')
          qualities.map((i) => qualitiesList.push({ name: i }))
          formData.qualities = qualitiesList
        }
        return await this.$store.dispatch('createOrUpdateRegionFarmerRegionContent', formData)
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    getTableData () {
      const tableRows = []
      const _content = this.regionContent
      for (const _data of _content) {
        const tempData = []
        tempData.push(_data.country_name)
        tempData.push(_data.region_name)
        tempData.push(_data.farmers_total)
        tempData.push(_data.altitude_min + ' - ' + _data.altitude_max)
        if (_.get(_data, 'coffee_species') || null) {
          let coffeeSpecs = _data.coffee_species.map(value => value.name).join(', ')
          coffeeSpecs = coffeeSpecs || 'N/A'
          tempData.push(coffeeSpecs)
        } else {
          tempData.push('N/A')
        }

        let mainStart = ''
        let mainEnd = ''
        if (_.get(_data, 'main_start_date')) {
          mainStart = moment(_data.main_start_date).format('MMM')
        }
        if (_.get(_data, 'main_end_date')) {
          mainEnd = moment(_data.main_end_date).format('MMM')
        }

        let flyStart = ''
        let flyEnd = ''
        if (_.get(_data, 'fly_start_date')) {
          flyStart = moment(_data.fly_start_date).format('MMM')
        }
        if (_.get(_data, 'fly_end_date')) {
          flyEnd = moment(_data.fly_end_date).format('MMM')
        }

        if (mainStart || mainEnd) {
          tempData.push((mainStart || 'N/A') + ' - ' + (mainEnd || 'N/A'))
        } else {
          tempData.push('N/A')
        }
        if (flyStart || flyEnd) {
          tempData.push((flyStart || 'N/A') + ' - ' + (flyEnd || 'N/A'))
        } else {
          tempData.push('N/A')
        }

        if (_.get(_data, 'qualities') || null) {
          let qualities = _data.qualities.map(value => value.name).join(', ')
          qualities = qualities || 'N/A'
          tempData.push(qualities)
        } else {
          tempData.push('N/A')
        }
        tempData.push('<a href="/admin/region/' + _data.id + '">Edit</a>')

        tableRows.push(tempData)
      }
      return tableRows
    },
    async createFileUploadLog (data) {
      const formData = this.$_.cloneDeep(data)
      const fileTsyncId = (formData.file && formData.file.tsync_id) ? formData.file.tsync_id : this.uuidV4()
      this.fileTsyncIdMapping[fileTsyncId] = data.file.file
      if (this.regionForm.file.file) {
        if (!formData.id) {
          formData.file = {
            tsync_id: fileTsyncId,
            extension: formData.file.name.split('.').pop()
          }
        } else {
          try {
            formData.file.extension = formData.file.name.split('.').pop()
          } catch (e) {
            this.errorLog(e)
          }
        }
      }
      formData.content_type = ContentTypes.FILE
      formData.content = this.$store.getters.getContentByAppModule(Menus.regionFG.key).id
      return await this.$store.dispatch('createOrUpdateRegionFarmerGroupContentItem', formData)
    },
    generatePagination (data) {
      try {
        this.paginateDict.nextUrl = _.get(data, 'next') || null
        this.paginateDict.previousUrl = _.get(data, 'previous') || null
        this.paginateDict.totalPage = _.get(data, 'total_page') || 0
        this.paginateDict.nextOffset = _.get(data, 'next_offset') || 0
        this.paginateDict.currentPage = _.get(data, 'current_page') || 0
        this.paginateDict.totalCount = _.get(data, 'count') || 0
        this.paginateDict.remainingCount = _.get(data, 'remaining_count') || 0
        this.paginateDict.firstPage = `/api/${this.apiPrefix}/?limit=${this.paginateDict.dataPerPage}`
        let offset = 0
        for (let i = 1; i < this.paginateDict.totalPage; i++) {
          offset += _.parseInt(this.paginateDict.dataPerPage)
        }
        this.paginateDict.lastPage = `/api/${this.apiPrefix}/?offset=${offset}&limit=${this.paginateDict.dataPerPage}`
        // Page number
        this.paginateDict.previousPageNumber = this.paginateDict.previousUrl ? this.paginateDict.currentPage - 1 : 0
        this.paginateDict.currentPageNumber = this.paginateDict.currentPage
        this.paginateDict.nextPageNumber = this.paginateDict.nextUrl ? this.paginateDict.currentPage + 1 : 0
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getPaginatedData (apiUrl) {
      this.loading = true
      const response = await this.$store.dispatch('getRegionsFromServer', { apiUrl: apiUrl })
      const data = response.data
      if (data.success) {
        this.generatePagination(data)
        this.$store.commit('setRegionFarmerRegionContent', data.results)
        await this.prepareTableContents()
      } else {
        this.errorLog(data)
      }
      this.loading = false
    },
    prepareTableContents () {
      try {
        this.tableData = []
        const tableRows = []
        const _content = this.$store.getters.getRegionFarmerGroupAllCountryRegionContents

        for (const _data of _content) {
          const tempData = []
          tempData.push(_data.country_name)
          tempData.push(_data.region_name)
          tempData.push(_data.farmers_total)
          tempData.push(_data.altitude_min + ' - ' + _data.altitude_max)
          if (_.get(_data, 'coffee_species') || null) {
            let coffeeSpecs = _data.coffee_species.map(value => value.name).join(', ')
            coffeeSpecs = coffeeSpecs || 'N/A'
            tempData.push(coffeeSpecs)
          } else {
            tempData.push('N/A')
          }

          let mainStart = ''
          let mainEnd = ''
          if (_.get(_data, 'main_start_date')) {
            mainStart = moment(_data.main_start_date).format('MMM')
          }
          if (_.get(_data, 'main_end_date')) {
            mainEnd = moment(_data.main_end_date).format('MMM')
          }

          let flyStart = ''
          let flyEnd = ''
          if (_.get(_data, 'fly_start_date')) {
            flyStart = moment(_data.fly_start_date).format('MMM')
          }
          if (_.get(_data, 'fly_end_date')) {
            flyEnd = moment(_data.fly_end_date).format('MMM')
          }

          if (mainStart || mainEnd) {
            tempData.push((mainStart || 'N/A') + ' - ' + (mainEnd || 'N/A'))
          } else {
            tempData.push('N/A')
          }
          if (flyStart || flyEnd) {
            tempData.push((flyStart || 'N/A') + ' - ' + (flyEnd || 'N/A'))
          } else {
            tempData.push('N/A')
          }

          if (_.get(_data, 'qualities') || null) {
            let qualities = _data.qualities.map(value => value.name).join(', ')
            qualities = qualities || 'N/A'
            tempData.push(qualities)
          } else {
            tempData.push('N/A')
          }
          tempData.push('<a href="/admin/region/' + _data.id + '">Edit</a>')

          tableRows.push(tempData)
        }
        return tableRows
        // return _.uniq(this.tableData, function (item, key, a) {
        //   return item.a
        // })
      } catch (e) {
        this.errorLog(e)
      }
    },
    async getContentsFromServer () {
      try {
        this.loading = true
        const limit = this.paginateDict.dataPerPage
        const apiUrl = `/api/${this.apiPrefix}/?search=1&offset=0&limit=${limit}`
        const response = await this.$store.dispatch('getRegionsFromServer', { apiUrl: apiUrl })
        const data = response.data
        if (data.success) {
          this.generatePagination(data)
          this.$store.commit('setRegionFarmerRegionContent', data.results)
          await this.prepareTableContents()
        } else {
          this.errorLog(data)
        }
        this.loading = false
      } catch (e) {
        this.errorLog(e)
      }
    },
    async applySearch () {
      const vm = this
      this.$v.searchDict.$touch()
      if (this.$v.searchDict.$invalid) {
        this.$notify({
          title: '',
          text: 'Please select column.',
          type: 'error',
          duration: 5000
        })
      } else {
        if (vm.searchDict.searchField.widget_type === 'text' || vm.searchDict.searchField.widget_type === 'number') {
          try {
            vm.loading = true
            const offset = 'offset=0'
            const limit = `limit=${vm.paginateDict.dataPerPage}`
            const searchParams = `${vm.searchDict.searchField.db_representation}=${vm.searchDict.searchText}`
            const apiUrl = `/api/${vm.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}`
            const response = await vm.$store.dispatch('getRegionsFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              vm.generatePagination(data)
              vm.$store.commit('setRegionFarmerRegionContent', data.results)
              await vm.prepareTableContents()
            } else {
              vm.errorLog(data)
            }
            vm.loading = false
          } catch (e) {
            vm.errorLog(e)
          }
        }
        if (vm.searchDict.searchField.widget_type === 'date_range') {
          try {
            vm.loading = true
            const fromDate = moment(vm.searchDict.fromDate, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' 00:00:00'
            const toDate = moment(vm.searchDict.toDate, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' 00:00:00'
            const offset = 'offset=0'
            const limit = `limit=${vm.paginateDict.dataPerPage}`
            const searchKeys = _.split(vm.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              const _to = searchKeys[1] || ''
              searchParams = `${_from}=${fromDate},${toDate}&${_to}=${fromDate},${toDate}`
            }
            const apiUrl = `/api/${vm.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}`
            const response = await vm.$store.dispatch('getRegionsFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              vm.generatePagination(data)
              vm.$store.commit('setRegionFarmerRegionContent', data.results)
              await vm.prepareTableContents()
            } else {
              vm.errorLog(data)
            }
            vm.loading = false
          } catch (e) {
            vm.errorLog(e)
          }
        }
        if (vm.searchDict.searchField.widget_type === 'number_range') {
          try {
            vm.loading = true
            const offset = 'offset=0'
            const limit = `limit=${vm.paginateDict.dataPerPage}`
            const searchKeys = _.split(vm.searchDict.searchField.db_representation, ',')
            let searchParams = ''
            if (!_.isEmpty(searchKeys)) {
              const _from = searchKeys[0] || ''
              const _to = searchKeys[1] || ''
              const _fromNumber = vm.searchDict.fromNumber || 0
              const _toNumber = vm.searchDict.toNumber || _fromNumber
              searchParams = `${_from}=${_fromNumber},${_toNumber}&${_to}=${_fromNumber},${_toNumber}`
            }
            const apiUrl = `/api/${vm.apiPrefix}/?search=1&${offset}&${limit}&${searchParams}`
            const response = await vm.$store.dispatch('getRegionsFromServer', { apiUrl: apiUrl })
            const data = response.data
            if (data.success) {
              vm.generatePagination(data)
              vm.$store.commit('setRegionFarmerRegionContent', data.results)
              await vm.prepareTableContents()
            } else {
              vm.errorLog(data)
            }
            vm.loading = false
          } catch (e) {
            vm.errorLog(e)
          }
        }
      }
    }
  },
  watch: {
    regionContent: {
      deep: true,
      handler (newValue) {
        this.region = _.cloneDeep(newValue)
      }
    }
  },
  mounted () {
  },
  created () {
    this.getContentsFromServer()
    window.onbeforeunload = () => (this.uploadPercentage ? true : null)
  }
}
</script>

<style lang="scss" scoped>

.clear-file {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 100%; // if file name is long than it wont overflow next close button
}

.dropdown-item:hover {
  cursor: pointer;
}
</style>
