<template>
  <RegionsFarmersGroups></RegionsFarmersGroups>
</template>

<script>
import RegionsFarmersGroups from '@/components/admin/regions-farmers-groups/RegionsFarmersGroups'

export default {
  name: 'RegionsFarmersGroupsView',
  components: {
    RegionsFarmersGroups
  }
}
</script>

<style scoped>

</style>
