<template>
  <div>
    <section class="my-40">
      <div class="container">
        <div class="row p-lg-0 p-20">
          <div class="col">
            <div>
              <ul class="nav nav-tabs text-center justify-content-center tab-with-dot">
                <li class="nav-item">
                  <a :class="{ active: activeTab === 'region' }" class="nav-link text-dark" data-toggle="tab"
                     href="#region" role="tab"
                     @click="setTabNameToPath('region')">
                    <div class="dot">
                      <div class="sm-dot"></div>
                    </div>
                    Regions </a>
                </li>
                <li class="nav-item">
                  <a :class="{ active: activeTab === 'group' }" class="nav-link text-dark" data-toggle="tab"
                     href="#group" role="tab"
                     @click="setTabNameToPath('group')">
                    <div class="dot">
                      <div class="sm-dot"></div>
                    </div>
                    Groups </a>
                </li>
                <li class="nav-item">
                  <a :class="{ active: activeTab === 'subgroup' }" class="nav-link text-dark" data-toggle="tab"
                     href="#subgroup" role="tab"
                     @click="setTabNameToPath('subgroup')">
                    <div class="dot">
                      <div class="sm-dot"></div>
                    </div>
                    Subgroups </a>
                </li>
              </ul>
              <div class="tab-content mt-40">
                <div id="region" :class="{ active: activeTab === 'region' }" class="tab-pane fade show"
                     role="tabpanel">
                  <AllCountryFarmerRegion></AllCountryFarmerRegion>
                </div>
                <div id="group" :class="{ active: activeTab === 'group' }" class="tab-pane fade show" role="tabpanel">
                  <AllCountryFarmerGroup></AllCountryFarmerGroup>
                </div>
                <div id="subgroup" :class="{ active: activeTab === 'subgroup' }" class="tab-pane fade show"
                     role="tabpanel">
                  <AllCountryFarmerSubGroup></AllCountryFarmerSubGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AllCountryFarmerRegion from '@/components/admin/regions-farmers-groups/all-country/AllCountryFarmerRegion'
import AllCountryFarmerSubGroup from '@/components/admin/regions-farmers-groups/all-country/AllCountryFarmerSubGroup'
import AllCountryFarmerGroup from '@/components/admin/regions-farmers-groups/all-country/AllCountryFarmerGroup'

export default {
  name: 'RegionsFarmersGroups',
  components: {
    AllCountryFarmerRegion,
    AllCountryFarmerSubGroup,
    AllCountryFarmerGroup
  },
  data () {
    return {
      loading: false,
      activeTab: 'region'
    }
  },
  created () {
  },
  mounted () {
    this.activeTab = this.$route.query.tab || 'region'
  },
  methods: {
    setTabNameToPath (tabName) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.params,
          tab: tabName
        }
      })
    }
  },
  watch: {
    '$route.query.tab' (newValue) {
      this.activeTab = newValue || 'region'
    }
  }
}
</script>

<style scoped>

</style>
